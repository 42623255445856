* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --primary: #3450a1;
  --primary-light: #7b96e8;
  --primary-dark: #031956;
  --secondary-blue: #3277ed;
  --secondary-purple: #ba1be0;
}

body {
  background-color: var(--primary);
  color: white;
  font-family: "Poppins", sans-serif;
  background-color: #e4e9fd;
  background-image: linear-gradient(
    to top right,
    var(--primary) 50%,
    var(--primary-light) 50%
  );
}

#root {
  height: 100vh;
  width: 100vw;
}

.app {
  padding: 1rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app header {
  margin: 2rem 0;

  display: flex;
  color: white;
  font-size: 3rem;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .app header {
    margin: 0 0;
    font-size: 2.2rem;
  }
}

#container {
  min-height: 0;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2rem 4rem;
}

@media only screen and (max-width: 768px) {
  #container {
    flex-grow: 1;
    width: 100%;
    padding: 1rem 0.5rem;
  }
}

/* COMPONENT search form */
form {
  /* min-height: 20vh; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
form input,
form button {
  padding: 0.5rem;
  font-size: 2rem;
  border: none;
  background: white;
}
form button {
  color: var(--primary-dark);
  background: #f7fffe;
  cursor: pointer;
  transition: all 0.3s ease;
}
form button:hover {
  background: var(--primary-dark);
  color: white;
}
.filter-todo {
  padding: 1rem;
}

@media only screen and (max-width: 768px) {
  form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  form input,
  form button {
    /* padding: 0.5rem; */
    font-size: 1rem;
    border: none;
    background: white;
  }
  form button {
    color: var(--primary-dark);
    background: #f7fffe;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  form button:hover {
    background: var(--primary-dark);
    color: white;
  }
  .filter-todo {
    padding: 1rem;
  }
}
/* END COMPONENT search form */

/* COMPONENT todo list */
.todo-list {
  margin: 1rem 0;
  flex: 1 1 auto;
  overflow-y: auto;
}
.todo-item {
  display: flex;
  width: 100%;
  background-color: var(--primary-dark);
  color: white;
  margin: 0.5rem 0 0.5rem 0;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .todo-item {
    padding: 0.2rem 0.5rem;
  }
}
.task-name {
  flex-grow: 1;
  padding: 0.2rem 0.5rem;
  margin-left: 0.5rem;
}
.done-btn {
  border: var(--secondary-purple) 0.2rem solid;
  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--primary-dark);
  border-radius: 50%;
  cursor: pointer;
}
.remove-btn {
  background: var(--primary-dark);
  color: var(--secondary-purple);
  border: none;
  padding: 0.3rem;
  cursor: pointer;
  font-size: 1rem;
}
.done-btn:hover {
  border-color: var(--secondary-blue);
  transition: all 50ms ease;
  /* background-color: var(--secondary-blue); */
}
.remove-btn:hover {
  color: white;
  transition: all 50ms ease;
}
.todo-done > .task-name {
  text-decoration-line: line-through;
  text-decoration-thickness: 2px;
  color: rgba(255, 255, 255, 0.794);
  transition: all 10s ease;
}
.todo-done > .done-btn {
  border-color: var(--secondary-blue);
  background-color: var(--secondary-blue);
  size: 1rem;
  color: white;
  transition: all 70ms ease;
}
/* END COMPONENT todo item */

/* COMPONENT add task form */
.add-task-form {
  width: 100%;
}
/* END COMPONENT add task form */

/*CUSTOM SELECTOR */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background-image: none;
}

/* Custom Select */
.select {
  margin: 1rem;
  position: relative;
  overflow: hidden;
}
select {
  color: var(--primary-dark);
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  width: 12rem;
}
@media only screen and (max-width: 768px) {
  .select {
    position: relative;
    overflow: visible;
  }
  select {
    width: 5rem;
  }
}

/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  background: var(--primary-dark);
  cursor: pointer;
  pointer-events: none;
}
/* Transition */

.select:hover::after {
  transform: scale(1.5);
}
